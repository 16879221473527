.cityHeader{
  height: 177px;
}

.border-radius {
  border-radius: 16px;
}

.border-width {
  padding: 0.3rem;
  border-bottom: 3px solid #E0E0E0;
}

.justify-content-space-between {
  justify-content: space-between;
}

.border-pink {
  padding: 0.3rem;
  border-bottom: 3px solid #b8238e;
}

.margin-top {
  margin-top: -6rem !important;
}