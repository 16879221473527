.free_sample_bg{
  position: relative; 
  // display: inline-block;
}
.timer_wrapper {
  background-color:yellow;
  border-bottom-left-radius: 8px;
  position: absolute;
  top: 0;
  right: 0px;
  color: black; 
  padding: 2px; 
}

.timer_wrapper_mb {
  background-color:yellow;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  position: absolute;
  top: 1;
  right: 5px;
  color: black; 
  padding: 2px; 
}

.timer_wrapper_hb{
  background-color:yellow;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  position: absolute;
  top: 0;
  right: 0px;
  color: black; 
  padding: 4px; 
}