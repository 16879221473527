.ak-intro-desktop {
  position: absolute;
  top: 250px;
}

.ak-intro-mobile {
  position: absolute;
  top: 80px;
}

.mh-370p {
  max-height: 370px;
}

.award > img:hover {
  box-shadow: 0 3px 6px rgba(#131314, .25);
}

.consultantion-form-mobile {
  position: relative;
  bottom: 10%;
}

.border-radius {
  border-radius: 3px;
}

.wrapper-header {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 80px;
}

.main-container {
  padding-top: 80px;
}

.thankyou-top-col {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.thankyou-bottom-col {
  position: absolute;
  left: 0;
  right: 0;
  top: 85%;
  transform: translateY(-15%);
}

#partner .video-container {
  width: 300px;
}

#partner .video-container .play-icon {
   position: absolute;
   top: 40%;
   left: 40%;
}

#partner .video-container img {
   max-width: 100%;
}

.our-purpose {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-flow: column;
}
  
.border-radius-8 {
  border-radius: 8;
}

.font-1 {
  font-size: 2rem;
  font-weight: 600;
}

.lovelocalAttributes {
  display: 'flex';
  flex-direction: 'row';
}

.lovelocal-attr {
  padding-right: '2rem' !important;
  padding-left: '2rem' !important;
  display: flex;
  flex-direction: column;
  align-items: 'center' !important;
}

.py-50p {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pt-50p {
  padding-top: 50px;
}

.pb-50p {
  padding-bottom: 50px;
}

.values-background {
  background: url('/Assets/about-us/values-background-desktop.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (max-width: 572px) {
    background: url('/Assets/about-us/values-background-mobile.png');
  }
}

.press-highlight-section {
  background: url('/Assets/about-us/values-background-desktop.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.horizontal-scroll-list {
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  scroll-behavior: smooth;
}

.opacity-10 {
  opacity: 0.75;
}

.border-radius-16 {
  border-radius: 16px;
}

.launchpad-image-box {
  width: 150px;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.our-positions-list {
  overflow-y: scroll;
  height: auto;
  max-height: 500px;
  @media screen and (max-width: 572px) {
    max-height: 100%;
  }
}

// .partner-wrapper-view {
//   overflow-x: hidden;
//   overflow-y: scroll;
//   height: auto;
//   max-height: 100% !important;
//   -webkit-overflow-scrolling: touch;
// }

.object-fit-contain {
  object-fit: contain;
}

.scroll-up-button {
  position: fixed;
  z-index: 1;
  bottom: 100px;
  width: 100vw;
}

.rotate-n90deg {
  transform: rotate(-90deg);
}

.border-radius-rounded {
  border-radius: 50%;
}
