
.wrapper {
  width: 0px;
  animation: fullView 0.5s forwards cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.store-shimmer-profile {
  height: 120px!important;
  width: 120px!important;
  border-radius: 16px;
  @media screen and (max-width: 992px) {
    height: 60px!important;
    width: 60px!important;
  }
}

.product-card {
  height: 150px;
}

.store-card {
  height: 150px;
  width: 40% !important;
  @media screen and (max-width: 992px) {
    width: 100% !important;
  }
}

.line-shimmer-card {
  height: 75px !important;
}

.footer-card {
  height: 250px;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

.fallback-loader {
  height: 8px!important;
  animation : shimmer 3s infinite linear;
   background: linear-gradient(to right, #e065be 4%, #fff2fb 25%, #e065be 36%);
   background-size: 1000px 100%;
}

.animate {
  animation: shimmer 3s;
  animation-iteration-count: infinite;
  background: linear-gradient(to right, #f0f0f5 5%, #dcdce0 25%, #f0f0f5 35%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
