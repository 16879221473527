.carousel-indicators {
  align-items: center;
  margin-bottom: 0px;
  margin-bottom: 0.1rem;
  @media screen and (min-width: 992px) {
    display: none;
  }
}

.carousel-indicators li {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: $white;
}

.carousel-indicators li.active {
  width: 7px;
  height: 7px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-image: url('/images/oval.png');
  width: 2.5rem;
  height: 2.5rem;
}

.carousel-control-prev-icon {
  transform: rotate(180deg);
}

.carousel-control-prev {
  margin-left: -0.7rem;
}

.preview .carousel-control-prev {
  margin-left: -3rem;
}

.carousel-control-next {
  margin-right: -0.7rem;
}

.preview .carousel-control-next {
  margin-right: -3rem;
}

.custom-carousel .carousel-control-prev,
.custom-carousel .carousel-control-next {
  @media screen and (max-width: 992px) {
    display: none!important;
  }
  width: auto!important;
  opacity: 1;
}

.preview .custom-carousel .carousel-control-prev,
.preview .custom-carousel .carousel-control-next,
.preview .custom-carousel .carousel-indicators {
  display: flex !important;
}

.preview .carousel-indicators {
  bottom: -30px;
}

.custom-carousel.no-control .carousel-control-prev,
.custom-carousel.no-control .carousel-control-next,
.custom-carousel.no-control .carousel-indicators {
  display: none!important;
}

.custom-carousel .carousel-inner {
  display: flex!important;
}

@each $number in $numbers {
  .custom-carousel-#{$number} .carousel-inner .active.carousel-control-prev {
    left: calc(-100% / #{$number})!important;
  }
  
  .custom-carousel-#{$number} .carousel-inner .next {
    left: calc(100% / #{$number})!important;
  }
  
  .custom-carousel-#{$number} .carousel-inner .prev {
    left: calc(-100% / #{$number})!important;
  }
  
  .custom-carousel-#{$number} .carousel-inner .carousel-item-left.active {
    transform: translateX(calc(-100% / #{$number}))!important;
  }
  
  .custom-carousel-#{$number} .carousel-inner .carousel-item-right.active {
    transform: translateX(calc(100% / #{$number}))!important;
  }
  
  .custom-carousel-#{$number} .carousel-inner .carousel-item-next {
    transform: translateX(calc(100% / #{$number}))!important;
  }
  
  .custom-carousel-#{$number} .carousel-inner .carousel-item-prev {
    transform: translateX(calc(-100% / #{$number}))!important;
  }

  .custom-carousel-#{$number} .carousel-inner .carousel-item-right,
  .custom-carousel-#{$number} .carousel-inner .carousel-item-left { 
    transform: translateX(0)!important;
  }
}