#global-search-modal {
    height: 60vh;
    @media screen and (min-width: 576px) {
        height: calc(100vh - 3.5rem);
    }
}

#search-results > div > div {
    border-bottom: 2px solid #E0E0E0;
}

#search-results > div:last-child > div {
    border: none;
}

.search-results-scroll {
    height: calc(60vh - 3.5rem);
    @media screen and (min-width: 576px) {
        height: calc(100vh - (3.5rem + 3.5rem));
    }
    overflow-y: scroll;
}

.category-image-wrapper {
    height: 100px;
}

.category-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.global-search-scroll {
  max-height: 85vh !important;
  min-height: auto!important;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  @media only screen and (max-width: 992px) {
    max-height: 80vh !important;
  }
}

.global-search-filter-count {
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mSite-global-search-selected-store {
    position: absolute;
    z-index: 1;
    background-color: #FFFFFF;
    width: 100vw;
}

.mt-50p {
    margin-top: 50px;
}

.search-product-image {
    width: 92px !important;
    height: 92px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.minh-600p {
    min-height: 600px;
    max-height: 600px;
    overflow-y: scroll;
    @media screen and (max-width: 450px) {
      min-height: 350px;
      max-height: 350px !important;
    }
  }

  .desktop-filter-modal {
    position: relative;
    left: 510px;
    width: 75%;
  }

  .variant-product {
    border: none;
    outline: none;
    background-color: #FFFFFF;
    font-weight: bold;
  }