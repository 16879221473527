.sticker-image-div {
  width: 80px !important;
}

.sticker-count-box {
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border-radius: 50%;
}

.sticker-count-badge {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  background-color: #f9ed32;
  border-radius: 50%;
  @media screen and (max-width: 500px) {
    background-color: #2b2ba0;
    color: #FFFFFF;
  }
}

.store-logo {
  width: 118px;
  height: 118px;
  border-radius: 18px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mSite-store-logo {
  width: 60px;
  height: 60px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: #f0f0ff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mSite-browse-product {
  position: fixed;
  z-index: 100;
  width: 100vw;
  bottom: 90px;
}

.mSite-category {
  position: fixed;
  bottom: 70px;
  right: 30px;
}

.mSite-category-list {
  position: fixed;
  bottom: 120px;
  right: 30px;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: #FFFFFF;
  border-radius: 16px;
  max-height: 300px !important;
  width: 250px;
  min-height: auto!important;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.ready-my-story-text {
  position: absolute;
  bottom: 0px;
  width: inherit;
}

.store-category-product {
  max-height: 650px !important;
}

.store-category-section {
  max-height: 650px !important;
  min-height: auto!important;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.store-product-section {
  max-height: 650px !important;
  min-height: auto!important;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.store-selected-category-bg {
  background-color: #fff2fb;
  // background-image: linear-gradient(to left, #B8238E4F 31% , #FFFFFF 70%);
  background-image: linear-gradient(to left, rgb(235 145 209 / 39%) 30%, #FFFFFF 70%);
}

.social-button {
  border-radius: 12px;
  background-color: #FFFFFF;
}

.desktop-store-items-section {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  overflow: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.category-button {
  white-space: nowrap;
  border-radius: 4px;
  font-weight: 400 !important;
}
.category-button.disabled {
  color: black !important;
}

.category-button.enabled {
  font-weight: 700 !important;
}


