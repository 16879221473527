#cart-bar {
    position: absolute;
    width: 100%;
    bottom: 0px;
    z-index: 500;
    transition: all 0.25s ease,
}

.fade-cartBar {
    bottom: -100px !important;
}

.highlight-offer-info {
    position: absolute;
    width: 100%;
    bottom: 56px;
    z-index: 500;
    transition: all 0.25s ease;
}

.order-tracking-div {
    position: absolute;
    width: 100%;
    bottom: 92px;
    z-index: 500;
    transition: all 0.25s ease,
}