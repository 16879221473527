
.h-616{
  height: 60vh;
  border-radius:16px, 16px, 0px, 0px;
}

.share_button{
  width: 32px;
  height: 32px;
  border-top-right-radius: 16px ;
  border-bottom-left-radius: 8px ;
  padding : 8px;
  background-color: rgba(239, 191, 225, 1) !important;
  display: flex;
  flex: none;
  border: none;
}

.share-bg {
  position: absolute;
  top: 0px;
  right: 0px;  
}

.h-48{
  height: 48px ;
}

.shop-text-truncate {
  height: calc(1.5rem * 1) !important;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @media screen and (max-width: 370px) {
    height: calc(1.4rem * 1.5) !important;
  }
}

.title-truncate {
  max-width: 185px;
  height: calc(1.8rem * 1) !important;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @media screen and (max-width: 370px) {
    height: calc(1.4rem * 1.5) !important;
  }
}

.category_image{

    object-fit: contain;
    align-self: center;
    width: 82px ;
    height:82px ;
    border-radius: 20px;
    
}

#stores .store .store-image {
  width: 120px;
  height: 136px;
  @media screen and (max-width: 992px) {
      width: 110px;
      height: 126px;
    }
    @media screen and (max-width: 768px) {
      width: 100px;
      height: 116px;
    }
    @media screen and (max-width: 448px) {
      width: 88px;
      height: 104px;
    }
  object-fit: contain;
}

.h-830 {
  height: 830px !important;
  overflow-y: scroll;
}

.category-position {

  position: fixed;
  top: 54px;
  z-index: 1;
}

.top-100 {
   margin-top: 132px;
   z-index: -1;
   overflow: auto;
}

.justify-content-space-between {
  justify-content: space-between;
}

.selected-category-btn {

  padding: 6px 8px;
  background: #FFFFFF;
  font-weight: bold;
  border:0px;
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  text-decoration: black;
  margin-left: 0%;
  margin-right: 0%;

}

.selected-category-btn-web {

  padding: 6px 8px;
  background: #FFFFFF;
  font-weight: bold;
  border-right: 1px solid;
  display: flex;
  flex-direction: column;
  align-content: center;
  text-decoration: black;
  margin-left: 0%;
  margin-right: 0%;
}

.store-selected-category-bg {
  background-color: #fff2fb;
  background-image: linear-gradient(to left, #b8238e50 , #FFFFFF) ;
}

.quickFilter_btn{
  padding: 4px 8px;
  background: #FFFFFF;
  border: 1px solid #fefefe;
  border-radius: 8px;
  font-weight: bold;
}

.quickFilter_btn--selected{ 
  background: #FFF6FD !important;
  border:1px solid #B8238E !important;
}

.text-truncate-cat {
  height: calc(3.5rem * 1) !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media screen and (max-width: 370px) {
    height: calc(1.4rem * 1.5) !important;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color:#b8238e!important;
}

.filter_btn {
 
  background-color: #fefefe;
  align-items: center;
  border: 0px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-content: center;
  margin: 6px;
  padding: auto;
}

.h-80 {
  height:80vh;
  overflow-y: scroll;
}

.shimmer_border {
  box-sizing: border-box;
  border: 1px solid #38b823;
  -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/350% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.0s infinite;
}

.shimmer {
  -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/350% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.0s infinite;
}

@keyframes shimmer {
  100% {-webkit-mask-position:left}
}

.fixed-top {
  position: absolute;
  width: 100%;
}
