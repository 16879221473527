.slick-prev {
  left: 5px !important;
  z-index: 1;
}

.slick-next {
  right: 5px !important;
  z-index: 1;
}

 .slick-prev:before, .slick-next:before  {
  content: '' !important;
}

.rotate-180deg {
  transform: rotate(180deg);
}
