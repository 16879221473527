

.seo-store .store-image {
  width: 104px;
  height: 104px;
  @media screen and (max-width: 576px) {
    width: 90px;
    height: 90px;
  }
  @media screen and (max-width: 448px) {
    width: 80px;
    height: 80px;
  }
  object-fit: contain;
}

.blinking_border {
  
  border-bottom: 1px solid green;
  animation-name: blinking;
  animation-duration: 1s;
  animation-iteration-count: 100;
}
@keyframes blinking {
  10% {
    border-color: white;
  }
}

.download-strip {

  background-image: url('/images/download-strip-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 48px;

}

.download-button{
  background-color: white;
  border-radius: 8px;
  border: 0px;
  align-content: center;
  @media screen and (max-width: 324px ) {
    width: 70px;
  }
}

.strip-font{
  font-size: 13px;

  @media screen and (max-width: 390px ) {
    font-size: 10px;
  }
  @media screen and (max-width: 330px ) {
    font-size: 9px;
  }
  @media screen and (max-width: 300px ) {
    font-size: 7px;
  }
  @media screen and (max-width: 262px ) {
    font-size: 6px;
  }
}

.white-space-nowrap{
  white-space: nowrap;
}