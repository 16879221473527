// @import '../variables.scss';

#combo-offer .product-image-div,
#product-offer .product-image-div,
#top-saving-home-offer .product-image-div,
#home-product .product-image-div,
#spend-offer .product-image-div,
#variant .product-image-div,
.appex-offer .product-image-div {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#value-offer .product-image-div {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#combo-offer .product-image-div {
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#store-combo-offer .bogo-product-image {
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#store-combo-offer .combo-offer-card {
  width: 248px !important;
}

#store-combo-offer .bogo-see-all-product-box {
  width: 85px;
  height: 85px;
}

#store-combo-offer .bogo-see-all-product-image {
  position: absolute;
  top: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#store-spend-offer .offer-desc-width {
  width: 200px !important;
}

#store-spend-offer .spend-offer-image {
  height: 68px !important;
  width: 68px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: break-spaces;
  line-height: normal;
}

#store-spend-offer .value-offer-image {
  background-size: cover;
  background-position: center;
  background-image: url('/images/value-offer-bg.png');
}

#store-spend-offer .value-offer-image.disabled {
  background-image: url('/images/value-offer--disabled-bg.png');
}

// #store-spend-offer .spend-free-tag {
//   position: absolute;
//   left: 0px;
//   top: 0px;
// }

#value-offer .text-truncate-2,
#combo-offer .text-truncate-2 {
  height: calc(1.9rem * 2) !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

#top-saving-home-offer .product-image-div,
#home-product .product-image-div
{
  height: 170px!important;
  @media screen and (min-width: 576px) {
    height: 180px!important;
  }
  @media screen and (min-width: 768px) {
    height: 190px!important;
  }
  @media screen and (min-width: 992px) {
    height: 200px!important;
  }
  @media screen and (min-width: 1200px) {
    height: 220px!important;
  }
}

#top-saving-home-offer,
#home-product {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  overflow-y: hidden;
  overflow-x: hidden;
  border: 1px solid #E0E0E0;
  border-radius: 2px;
  width: 48% !important;
  height: 340px !important;
  @media screen and (min-width: 330px) {
    width: 45% !important;
    height: 350px !important;
  }
  @media screen and (min-width: 360px) {
    width: 40% !important;
    height: 363.75px !important;
  }
  @media screen and (min-width: 450px) {
    width: 35% !important;
    height: 375.5px !important;
  }
  @media screen and (min-width: 576px) {
    width: 30% !important;
    height: 410px !important;
  }
  @media screen and (min-width: 768px) {
    width: 26% !important;
    height: 420px !important;
  }
  @media screen and (min-width: 992px) {
    width: 23% !important;
    height: 430px !important;
  }
  @media screen and (min-width: 1200px) {
    width: 21% !important;
    height: 448px !important;
  }
}

#reward.offer-view, #offer-coupon.offer-view {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 159px !important;
  @media screen and (min-width: 330px) {
    width: 170px !important;
  }
  @media screen and (min-width: 360px) {
    width: 175px !important;
  }
  @media screen and (min-width: 450px) {
    width: 185px !important;
  }
  @media screen and (min-width: 576px) {
    width: 195px !important;
  }
}

.see-all-reward {
  width: 100%;
  aspect-ratio: 1;
  height: calc(159px - 1rem);
  @media screen and (min-width: 330px) {
    height: calc(170px - 1rem);
  }
  @media screen and (min-width: 360px) {
    height: calc(175px - 1rem);
  }
  @media screen and (min-width: 450px) {
    height: calc(185px - 1rem);
  }
  @media screen and (min-width: 576px) {
    height: calc(195px - 1rem);
  }
}

#reward.offer-view .main-content,
#offer-coupon.offer-view .main-content {
  width: 100%;
  height: calc(159px - 1rem);
  @media screen and (min-width: 330px) {
    height: calc(170px - 1rem);
  }
  @media screen and (min-width: 360px) {
    height: calc(175px - 1rem);
  }
  @media screen and (min-width: 450px) {
    height: calc(185px - 1rem);
  }
  @media screen and (min-width: 576px) {
    height: calc(195px - 1rem);
  }
}
//Offers/Reward
#reward.referral-view {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 600px !important;
}

#reward.referral-view .referral-content {
  width: 100%;
  height: 214px;
}


.header-ribbon {
  position: relative;
  width: 4.5rem;
  padding-top: 0.5rem;
  padding-bottom: 1.75rem;
  margin-top: -1.25rem;
  color: #FFFFFF;
  background-color: #2b2ba0;
  text-align: center;
}

.header-ribbon:before {
  content: '';
  position: absolute;
  top: 0;
  right: -0.5rem;
  border-top: 0.25rem solid transparent;
  border-right: 0.25rem solid transparent;
  border-bottom: 0.25rem solid #161666;
  border-left: 0.25rem solid #161666;
}

.header-ribbon:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1rem solid transparent;
  border-right: 2.35rem solid #FFFFFF;
  border-bottom: 1rem solid #FFFFFF;
  border-left: 2.35rem solid #FFFFFF;
}

.unused-offers-count {
  padding: 0.2rem 0.25rem;
  font-size: 0.7rem;
  color: #FFFFFF;
  border-radius: 50%;
}

.scratch-card-info {
  max-width: 400px;
  height: 56px;
}

.repeat-product-image {
  width: 90px !important;
  height: 90px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

#home-appex-offers .appex-offer,
#offer-appex-offers .appex-offer {
  width: 560px;
  @media screen and (max-width: 992px) {
    width: 520px;
  }
  @media screen and (max-width: 768px) {
    width: auto;
  }
}

#spend-offer-summary .offer-image-div {
   width: 90px;
   height: 90px;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 4px;
   border: 1px solid #E0E0E0;
   border-radius: 2px;
}

#discount-offer-summary .offer-image-div {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-top-saving-offer .product-image-div {
  width: 100%;
  height: 104px;
  @media screen and (min-width: 576px) {
    height: 122px!important;
  }
  @media screen and (min-width: 768px) {
    height: 140px!important;
  }
  @media screen and (min-width: 992px) {
    height: 160px!important;
  }
  @media screen and (min-width: 1200px) {
    height: 170px!important;
  }
  display: flex;
  align-items: center;
  justify-content: center;
}

#offer-selling-stores {
  height: 320px;
  @media screen and (min-width: 992px) {
      height: 90vh;
   }
  transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
}

#offer-selling-stores .product-image-div {
   width: 100px;
   height: 100px;
}

// .offers-spend-background {
//   background: url('../../components/common/images/coupon_background.png');
//   background-position: center;
//   background-size: cover;
//   background-repeat: no-repeat;
// }

.offers-spend-background .fs-6 {
  font-size: 2rem!important;
}

.top-saving-save-tag-badge {
  position: absolute;
  top: 0px;
  left: -16px;
}

#store-app-ex-offer .app-ex-card {
  width: 300px !important;
}

#store-app-ex-offer .app-ex-image {
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#store-app-ex-offer .app-ex-cart-button {
  width: 96px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  bottom: 14px;
  left: 0px;
  right: 0px;
}

.text-truncate-22 {
  height: calc(1.4rem * 2) !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media screen and (max-width: 411px) {
    height: calc(3.8rem) !important;
  }
}

.max-saving-container {
  height: 350px;
  @media screen and (max-width: 411px) {
    height: 255px;
  }
}

.border-radius-15 {
  border-radius: 15px;
}

.offer-text {
  position: absolute;
  top: 7%;
  width: 100%;
  text-align: center;
}

.offer-category-text {
  position: absolute;
  top: 25%;
  width: 100%;
  text-align: center;
  z-index: 1;
}

.category-image-container {
  position: absolute;
  top: 35%;
  width: 100%;
  text-align: center;
  @media screen and (max-width: 411px) {
    top: 28%;
  }
}

.category-image {
  height: 200px;
  width: 80%;
  @media screen and (max-width: 411px) {
    height: 150px;
    width: 70%;
  }
}

.offer-font {
  font-size: 1.7rem !important;
  line-height: normal;
  @media screen and (max-width: 411px) {
    font-size: 1.4rem !important;
  }
}

.category-font {
  font-size: 1.3rem !important;
  line-height: normal;
  @media screen and (max-width: 411px) {
    font-size: 1.2rem !important;
  }
}

.max-height-5 {
  max-height: 70px;
  text-overflow: ellipsis;
  white-space: break-spaces;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.brand-product-container {
  height: 222px;
}

.brand-text {
  position: absolute;
  left: 25px;
  top: 80px;
  width: 26%;
}

.brand-font {
  font-size: 1.4rem !important;
  line-height: normal;
  color: green;
}

.brand-name {
  position: absolute;
  left: 25px;
  padding: 10px 20px;
  background-color: palevioletred;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.brand-image {
  height: 40px;
  width: 100px;
}

.must-try-product-container {
  height: 180px;
}

.shop-now {
  position: absolute;
  left: 15px;
  bottom: 15px;
  border-radius: 10px;
  padding: 5px 15px;
  background-color: white;
}

.brand-product-name {
  position: absolute;
  left: 15px;
  top: 18px;
  width: calc(40% - 20px);
}

.brand-product-font {
  font-size: 0.9rem !important;
  line-height: normal;
  color: green;
}

.product-text-truncate-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}


.brand-product-desc {
  position: absolute;
  left: 15px;
  top: 60px;
  width: calc(40% - 20px);
}

.brand-product-desc-font {
  font-size: 0.7rem !important;
  line-height: normal;
  color: green;
}

.brand-product-price {
  position: absolute;
  left: 15px;
  bottom: 55px;
  width: calc(40% - 20px);
}

.border-radius-25 {
  border-radius: 25px;
}

.top-saving-offer-tag-badge {
  position: absolute;
  top: -8px;
  left: -8px;
}

.variant-product {
  border: none;
  outline: none;
  background-color: #FFFFFF;
  font-weight: bold;
}

.spend-offer-modal {
  position: absolute;
  width: 264px;
  // height: 56px;
  // left: calc(50% - 117px);
  z-index: 1;
  text-align: center;
}

.spend-offer-text {
  position: absolute;
  top: 24px;
  color: #005317;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  left: calc(50% - 70px);
  height: 3.8rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.valid-offer-text {
  position: absolute;
  top: 90px;
  color: #282828;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  left: calc(50% - 42px);
  line-height: 20px;
}

.spend-shop-now {
  position: absolute;
  top: 132px;
  left: calc(50% - 24px);
}

.shop-button {
  border: none;
  color: #38B823;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  background: transparent;
}