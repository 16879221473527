.location-search .autocomplete-dropdown-container {
  z-index: 1;
  max-height: 300px!important;
  box-shadow: 0 3px 6px rgba(#131314, .25);
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.location-search .suggestion-item {
  background-color: #FFFFFF;
  cursor: 'pointer';
  padding: 0.5rem;
  border-bottom: 1px solid #E0E0E0;
}

.location-search .suggestion-item.active {
  background-color: #F4F4F4;
  cursor: 'pointer';
  padding: 0.5rem;
  font-weight: bold;
}

.location-search .loading {
  background-color: #FFFFFF;
  padding: 1rem;
  font-size: 1.2rem;
}

.location-search {
  height: 400px;
}

.manage-loc-content {
  max-height: 82vh;
}