#submit-order-feedback {
    height: 90vh;
    @media screen and (max-width: 992px) {
        height: 100%;
        overflow-y: scroll;
    }
}

#submit-order-feedback .sticker-section {
    max-width: 342px !important;
}