
.nav-categories {
  position: relative;
}

.nav-categories:hover {
  .nav-categories-primary {
    display: block;
  }
}

.nav-categories-primary {
  position: absolute;
  display: none;
  top: 60px;
  z-index: 1051;
  left: 0;
}

.shop-by-category {
  background-color: #FFFFFF;
  max-height: 500px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: visible;
}

.nav-sub-category {
  display: none;
  position: absolute;
  z-index: 1051;
  left: 330px;
  top: 0px;
  height: 500px;
  overflow-y: scroll;
  .btn {
      width: 330px;
      &:hover {
          background-color: #FFFFFF!important;
          color: #b8238e!important;
          .sub-category-text {
              font-weight: 700!important;
          }
      }
  }
}

.nav-category {
  &:hover{
      background-color: #ffebf9!important;
      .nav-sub-category {
          display: block!important;
          background-color: #FFFFFF!important;
      }
  }
}

.nav-category > a .btn {
  width: 330px;
  &:hover {
      color: #b8238e!important;
      .category-text {
          font-weight: 700!important;
      }    
  }
}
