#store-details  #map-div {
  width: 400px;
  height: 210px;
}

#store-details .store-gallery {
  width: 400px;
}

#store-details .store-gallery >div {
    margin-right: 10px;
}

#store-details .store-gallery:last-child {
  margin-right: 0;
}

#store-details .store-image-div {
    width: 100px;
    height: 100px;
}

.store-cover {
  width: 250px;
  height: 250px;
  object-fit: contain;
  @media screen and (max-width: 992px) {
    height: 220px;
  }
  @media screen and (max-width: 768px) {
    height: 180px;
  }
  @media screen and (max-width: 576px) {
    height: 160px;
  }
  @media screen and (max-width: 448px) {
    height: 140px;
  }
}

#store-details .store-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#store-details #review>div,
#store-details #combo-offer>div,
#store-details #product-offer>div {
  @media screen and (max-width: 768px ) {
    border-bottom: 1px solid $disabled;
  }
}

#store-details #review:last-child > div,
#store-details #combo-offer:last-child > div,
#store-details #product-offer:last-child > div{
  @media screen and (max-width: 768px ) {
    border: none !important;
  }
}

.offer-alert-list .product-image-div {
  width: 96px!important;
  height: 96px!important;
  border: 1px solid $disabled
}

#store-coupons .coupon-desc-width {
  width: 170px !important;
}

#store-coupons .coupon-card {
  height: 56px;
}

#store-combo-offer .bogo-see-all-plus-badge {
  position: absolute;
  top: 35px;
  left: 30px;
  z-index: 1;
}

// below 2 classes are being used in many componenets
.store-top-saving-offer .top-saving-card {
  width: 120px !important;
}

.store-top-saving-offer .top-saving-image {
  width: 120px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.discount-badge {
  position: absolute;
  top: -8px;
  left: -8px;
}

#store-repeat-product .repeat-product-card {
  width: 300px !important;
}

#store-repeat-product .repeat-product-image-section {
  width: 90px !important;
}

#store-repeat-product .repeat-product-image {
  width: 90px !important;
  height: 90px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.store-product-bogo-save-tag {
  position: absolute;
  top: -14px;
  left: -15px;
}

.bogo-save-tag-see-all {
  position: absolute;
  top: -15px;
  left: -14px;
}

.store-product-save-tag {
  position: absolute;
  top: -4px;
  left: -2px;
}

.store-offer-save-tag {
  position: absolute;
  top: -4px;
  left: -6px;
}

.top-category-image {
  height: 40px;
  width: 40px;
}

.sticker-image-div {
  width: 80px !important;
}

.sticker-count-box {
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border-radius: 50%;
}

.sticker-count-badge {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  background-color: #f9ed32;
  border-radius: 50%;
  @media screen and (max-width: 500px) {
    background-color: #2b2ba0;
    color: #FFFFFF;
  }
}

.store-logo {
  width: 118px;
  height: 118px;
  border-radius: 18px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mSite-store-logo {
  width: 60px;
  height: 60px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: #f0f0ff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mSite-browse-product {
  position: fixed;
  z-index: 100;
  width: 100vw;
  bottom: 90px;
}

.mSite-category {
  position: fixed;
  bottom: 70px;
  right: 30px;
}

.mSite-category-list {
  position: fixed;
  bottom: 120px;
  right: 30px;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: #FFFFFF;
  border-radius: 16px;
  max-height: 300px !important;
  width: 250px;
  min-height: auto!important;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.ready-my-story-text {
  position: absolute;
  bottom: 0px;
  width: inherit;
}

.desktop-section-tabs {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  overflow: hidden;
}

.store-category-product {
  max-height: 650px !important;
}

.store-category-section {
  max-height: 650px !important;
  min-height: auto!important;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.store-product-section {
  max-height: 650px !important;
  min-height: auto!important;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.store-selected-category-bg {
  background-color: #fff2fb;
  background-image: linear-gradient(to left, #b8238e50 , #FFFFFF);
}

.social-button {
  border-radius: 12px;
  background-color: #FFFFFF;
}

.desktop-store-items-section {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  overflow: hidden;
}

.previously-bought {
  position: absolute;
  bottom: 0;
}

.out-of-stock-box {
  position: absolute;
  top: 50;
  background-color: black;
}

.pointer-events-none {
  pointer-events: none;
}

