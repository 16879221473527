.sc__inner {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.scratchcard-wrapper {
    display: block;
    margin: 0 auto;
    width: 280px;
    height: 280px;
    overflow: hidden;
    @media screen and (max-width: 992px) {   
        width: 250px;
        height: 250px;
    }
    @media screen and (max-width: 768px) {
        width: 220px;
        height: 220px;
    }
    @media screen and (max-width: 576px) {
        width: 200px;
        height: 200px;
    }
}

.scratchcard-container {
    position: relative;
    height: 100%;
    max-width: 100%;
    overflow: hidden;
}

.scratchcard-container > img {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
}

.scratchcard-container canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
}

.scratchcard-innerhtml {
    border: 1px dotted #a4a4aa;
    border-radius: 4px;
}

.scratchcard-innerhtml img {
  object-fit: contain;
  max-width: 60%;
}

.scratch-card-mobile-view {
    position: fixed;
    background: rgba(0,0,0,0.9);
    width: 100%;
    height: 100%;
    z-index: 1200;
}

.scratch-card-mobile-view .close-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px 10px;
}