
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-image: url('/images/oval.png');
  width: 2.5rem;
  height: 2.5rem;
}

.carousel-control-prev-icon {
  transform: rotate(180deg);
}

.carousel-control-prev, .carousel-control-next {
  opacity: 1;
}

.carousel-control-prev {
  margin-left: -0.7rem;
}

.carousel-control-next {
  margin-right: -0.7rem;
}

.carousel-indicators {
  align-items: center;
  margin-bottom: 0px;
  @media screen and (min-width: 992px) {
    display: none;
  }
}

.carousel-indicators li {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.carousel-indicators li.active {
  width: 7px;
  height: 7px;
}

.product-category {
  @media screen and (min-width: 992px) {
    flex: 0 0 20%!important;
    max-width: 20%!important;
  }
}

#top-picks-shops .store-mobile {
  width: 100px;
}

#top-picks-shops .store-desktop {
  width: 222px;
}

.top-picks-shop-image-mobile {
  width: 100px;
  height: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-picks-image {
  object-fit: contain;
}

.top-picks-shop-image-desktop {
  width: 222px;
  height: 160px;
}

.top-pick-offer {
  position: absolute;
  top: 85px;
  z-index: 1;
  border-radius: 3px;
}

.w-90p {
  width: 90px;
}

#best-rated-shops .store
 {
  width: 410px;
  @media screen and (max-width: 992px) {
    width: 420px
  }
  @media screen and (max-width:768px) {
    width: 400px;
  }
  @media screen and (max-width:576px) {
    width: 300px;
  }
}

.store-image-div {
  width: 120px;
  height: 136px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 992px) {
      width: 110px;
      height: 126px;
    }
    @media screen and (max-width: 768px) {
      width: 100px;
      height: 116px;
    }
    @media screen and (max-width: 448px) {
      width: 88px;
      height: 104px;
    }
  object-fit: contain;
}

#home-refer-shop img {
   width : 100%;
   height: 100%;
}

#custom-carousel .carousel-control-prev,
#custom-carousel .carousel-control-next {
  @media screen and (max-width: 992px) {
    display: none!important;
  }
}

@media screen and (min-width: 992px) {
  #custom-carousel .carousel-inner {
    display: flex!important;
  }
  
  #custom-carousel .carousel-inner .active.carousel-control-prev {
    left: -50%!important;
  }
  
  #custom-carousel .carousel-inner .next {
    left: 50%!important;
  }
  
  #custom-carousel .carousel-inner .prev {
    left: -50%!important;
  }
  
  #custom-carousel .carousel-control-prev,
  #custom-carousel .carousel-control-next {
    width: auto!important;
  }
  
  #custom-carousel .carousel-inner .carousel-item-left.active {
    transform: translateX(-50%)!important;
  }
  
  #custom-carousel .carousel-inner .carousel-item-right.active {
    transform: translateX(50%)!important;
  }
  
  #custom-carousel .carousel-inner .carousel-item-next {
    transform: translateX(50%)!important;
  }
  
  #custom-carousel .carousel-inner .carousel-item-prev {
    transform: translateX(-50%)!important;
  }
  
  #custom-carousel .carousel-inner .carousel-item-right,
  #custom-carousel .carousel-inner .carousel-item-left { 
    transform: translateX(0)!important;
  }  
}

#sell-on-lovelocal .close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.tilt-box {
  position: absolute;
  left: 7%;
  top: -7%;
  transform: rotate(-7deg);
  height: 120%;
  width: 48%;
  @media screen and (max-width:768px) {
    width: 50%;
  }
  @media screen and (max-width:576px) {
    width: 60%;
  }
}

.store-offer-banner {
  width: 500px!important;
  height: 220px!important;
  @media screen and (max-width: 992px) {
    width: 370px!important;
    height: 170px!important;
  }
  @media screen and (max-width:768px) {
    width: 350px!important;
    height: 160px!important;
  }
  @media screen and (max-width:576px) {
    width: 300px!important;
    height: 155px!important;
  }
}

.store-offer-banner-img {
  width: 100%!important;
  max-height: 100%!important;
  border-radius: 8px!important;
  border: 1px solid grey;
}

.offer-value-font {
  font-size: 2.5rem !important;
  line-height: normal;
  color: black;
}

.max-height-3 {
  max-height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.yellow-strip {
  position: absolute;
  bottom: 4rem;
  width: 100%;
  height: 4rem;
  text-align: center;
  background-color: yellow;
  @media screen and (max-width: 992px) {
    bottom: 2.8rem !important;
  }
}

#popular-product .product-image-div {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  // @media screen and (max-width: 393px) {
  //   // width: 90px!important;
  // }
}

#popular-product .popular-product-add {
  position: relative;
  z-index: 1;
  top: -15px;
}

#home-appex-offers .carousel,
#offer-appex-offers .carousel {
  display: flex !important;
  flex-direction: column-reverse;
}

#home-appex-offers .carousel-indicators,
#offer-appex-offers .carousel-indicators {
  position: relative !important;
  margin-bottom: 0 !important;
  padding: 4px 0 !important;
}

#home-appex-offers .carousel-indicators li,
#offer-appex-offers .carousel-indicators li{
  // background-color: secondary !important;
  border: 0 !important;
}

#home-appex-offers .carousel-indicators li.active,
#offer-appex-offers .carousel-indicators li.active{
  background-color: $primary !important;
  width: 5px !important;
  height: 5px !important;
}

#home-local-shops .store > div,
#home-local-shops .store > a,
#home-local-shops  #refer-shop-card {
  width: 420px;
  @media screen and (max-width: 992px) {
    width: 390px;
  }
  @media screen and (max-width: 768px) {
    width: 370px;
  }
  @media screen and (max-width: 576px) {
    width: 320px;
  }
  @media screen and (max-width: 448px) {
    width: 300px;
  }
  @media screen and (max-width: 360px) {
    width: 280px;
  }
}

#home-local-shops .store .store-image {
  width: 104px;
  height: 104px;
  @media screen and (max-width: 992px) {
    width: 92px;
    height: 92px;
  }
  @media screen and (max-width: 768px) {
    width: 84px;
    height: 84px;
  }
  @media screen and (max-width: 576px) {
    width: 76px;
    height: 76px;
  }
  @media screen and (max-width: 448px) {
    width: 70px;
    height: 70px;
  }
  @media screen and (max-width: 360px) {
    width: 60px;
    height: 60px;
  }
  object-fit: contain;
}

.text-gray {
  color: #646466 !important;
}

//Revamped classes with r__ prefix

.r__banner_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  overflow: hidden;
}

.r__banner_slide {
  display: block;
  max-width: 1170px;
  max-height: 300px;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 42px;
}

.r__supersaving_wrapper{
  display: flex;
  gap: 10px
}

.refer_card{
  width: 320px;
  height: 126px;
  background: linear-gradient(180deg, #5757D2 0%, #000088 100%);
  border-radius: 16px;
}

