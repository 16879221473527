#cart {
  height: calc(100% - 96px);
  @media screen and (max-width: 992px) {
    height: 100%;
  }
}

.checkout-section {
  // position: fixed;
  // bottom: 0px;
  width: 100%!important;
  @media screen and (min-width: 576px){
    width: 420!important;
  }
}

#cart-product .cart-product-image {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.free-sample-tag {
  position: absolute !important;
  top: 4px;
  left: 0px;
  height: 20px !important;
  z-index: 200;
  background-color: #b8238e;
  padding-left: 4px;
  padding-right: 4px;
}

.checkout-store {
  height: calc(100% - 32px);
}

.checkout-product-section {
  height: calc(100% - 41px);
  min-height: auto!important;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  position: relative;
}

.process-add-to-cart {
  width: 50%;
  height: 2px;
  background: #b8238e;;
  position: relative;
  animation: processing-add-to-cart 0.5s;
  animation-iteration-count: infinite;
}

@keyframes processing-add-to-cart {
  from {left: -25%;}
  to {left: 125%;}
}

#cart-applicable-offer .offer-product-image-box {
  width: 100%;
  aspect-ratio: 1;
  // height: 85px;
}

#cart-applicable-offer .bogo-offer-product-image {
  object-fit: contain;
}

#cart-applicable-offer .discount-offer-product-image {
  // width: 80px;
  // height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#cart-applicable-offer .bogo-see-all-plus-badge {
  position: absolute;
  top: 30px;
  left: 30px;
}

#cart-applicable-offer .cart-offer-content-section {
  width: 156px !important;
  display: flex;
}

.cart-available-offer-save-tag {
  position: absolute;
  top: -10px;
  left: -10px;
}

// .mov-section {
  // position: fixed;
  // bottom: 56px;
  // width: 100%!important;
  // z-index: 500;
  // @media screen and (min-width: 576px){
  //   width: 500px!important;
  // }
// }

// .mov-section-top {
  // position: fixed;
  // width: 100%!important;
  // z-index: 500;
  // @media screen and (min-width: 576px){
  //   width: 500px!important;
  // }
// }

.gap-10 {
  gap: 10px;
}

.gradient-green {
  border: 1px solid #D8EEDE !important;
  border-radius: 16px;
  background: linear-gradient(to right, #ffffff 70%, #f1faf0 100%);
}

.text-strike-through {
  text-decoration: line-through !important;
}

.align-self-end {
  align-self: flex-end;
}

.payment-box {
  display: flex;
  gap: 16px;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}
