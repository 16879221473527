.languageMain {
    width: 100%;
    background: #fff;
}

.languageHead {
    width: 100%;
    padding: 24px 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: #2b2ba0;
}

.languageHeadText {
    width: 90%;
    text-align: center;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
}

.languageHeadClose {
    width: 10%;
    text-align: right;
    color: #fff;
    cursor: pointer;
}

.languageContainer {
    width: 100%;
    padding-bottom: 30px;
}

.language {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 12px 0;
    border: none;
    background: none;
    outline: none;
}

.active {
    background: #eaeaf6;
}