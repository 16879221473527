/*
  Bootstrap config overrides
*/

$grid-columns: 24;

// default color override
$primary:       #b8238e;
$primary-50:    #b8238e50;
$primary-dark:  #630048;
$primary-light: #fff2fb;
$pink:          #e065be;
$pink-light:    #FFF6FD;
$pink-pale:     #ffebf9;
$pink-lighter:  #EFBFE1;
$link:          #b8238e;
$secondary:     #999999;
$medium:        #666666;
$medium-light:  #646466;
$red:           #d34e66;
$danger:        #A30000;
$danger-light:  #f5cece;
$light:         #F4F4F4;
$dark:          #333333;
$white:         #FFFFFF;
$white-02:      #FFFFFF33;
$white-72:      #FFFFFFB8;
$disabled:      #E0E0E0;
$black:         #131314;
$black-72:      #000000B8;
$black-64:      #000000A3;
$black-50:      #C2C2C2;
$brown:         #8a584b;
$violet:        #2b2ba0;
$violet-lighter:#f0f0ff;
$violet-dark:   #161666;
$yellow:        #f9ed32;
$yellow-light:  #fbf47c;
$green:         #38b823;
$green-light:   #e8f7e5;
$green-72:      #38b823B3;
$green-dark:    #005317;
// $green-light:   #ebfff3;
$green-dark:    #005317;
$blue:          #0067d7;
$blue-light:    #d6eaff;
$blue-lighter:  #6d6de8;
$orange:        #d76200;
$orange-light:  #ffe9d6;
$gray:          #a4a4a4;
$gray-light:    #a0a0a3;
$gray-lighter:  #f0f0f5;
$grey:          #f5f5f5;
$grey-light:    #dcdce0;
$grey-lighter:  #FAFAFA;
$info:           #d34e66;

$theme-colors: (
  "primary":        $primary,
  "primary-50":     $primary-50,
  "primary-dark":   $primary-dark,
  "primary-light":  $primary-light,
  "pink":           $pink,
  "pink-light":     $pink-light,
  "pink-pale":      $pink-pale,
  "pink-lighter":   $pink-lighter,
  "link":           $link,
  "secondary":      $secondary,
  "medium":         $medium,
  "medium-light":   $medium-light,
  "info":           $info,
  "red":            $red,
  "danger":         $danger,
  "danger-light":   $danger-light,
  "light":          $light,
  "dark":           $dark,
  "white":          $white,
  "white-02":       $white-02,
  "white-72":       $white-72,
  "disabled":       $disabled,
  "black":          $black,
  "black-72":       $black-72,
  "black-64":       $black-64,
  "black-50":       $black-50,
  "brown":          $brown,
  "violet":         $violet,
  "violet-lighter": $violet-lighter,
  "yellow":         $yellow,
  "yellow-light":   $yellow-light,
  "green":          $green,
  "green-72":       $green-72,
  "green-light":    $green-light,
  "green-dark":     $green-dark,
  "blue":           $blue,
  "blue-light":     $blue-light,
  "blue-lighter":   $blue-lighter,
  "orange":         $orange,
  "orange-light":   $orange-light,
  "gray":           $gray,
  "gray-light":     $gray-light,
  "gray-lighter":   $gray-lighter,
  "grey":           $grey,
  "grey-light":     $grey-light,
  "grey-lighter":   $grey-lighter,
);

$custom-radio-indicator-icon-checked: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='1.1rem' height='1.1rem' viewBox='0 0 16 16'%3e%3ccircle cx='8' cy='8' r='8' fill='#{$primary}'/%3e%3c/svg%3e"), "#", "%23");

$box-shadow: 0 3px 6px rgba($black, .25);

$border-color: $disabled;
$borderColorDark: #b1b1b1;
$borderColorLight: #d3d3d3;


$border-radius:    2px;
$border-radius-lg: 4px;
$border-radius-sm: 1px;
$border-radius-xlg: 8px;

$buttonColor: $white;
$buttonFontColor: $violet;

//// Font size
$xSmallFont: 8px; //8px
$smallFont: 10px; //10px;
$regularFont: 12px; //12px
$mediumFont: 14px; //14px
$largeFont: 16px; //16px
$xlargeFont: 18px; //18px
$xllargeFont: 20px; // 20px
$xxlargeFont : 24px; //24px

//// Font weight
$normalWeight: 400;
$mediumWeight: 700;
$heavyWeight: 900;

//// Background
$bgWhite: #fff;
$bgDkGray: #ddd;
$bgGray: #ccc;
$bgLtGray: #f5f5f5;

//// Text colors variables
$textBlack: #000;
$textPrimary: #000000;
$textSecondary: #878787;
$textTertiary: #999999;
$textViolet: $violet;
$textWhite: $white;


$errorText: #ff4b55;

//// Box shadow
$shadowBottom: 0px 4px 4px rgba(185, 185, 185, 0.25);
$shadowTop: 0px -4px 4px rgba(185, 185, 185, 0.25);

$inputHeight: 45px;
$buttonBorderRadius: 4px;

// Padding
$xsmallPadding: 5px;
$smallPadding: 10px;
$regularPadding: 15px;
$mediumPadding: 20px;
$largePadding: 25px;

//Margin
$xsmallMargin: 5px;
$smallMargin: 10px;
$regularMargin: 15px;
$mediumMargin: 20px;
$largeMargin: 25px;

//media Query
$minWidth: min-width;
$maxWidth: max-width;
$media: screen;
$xs: '420px';
$sm: '768px';
$lg: '1200px';

// spacers override
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: ($spacer * .75),
  4: $spacer,
  5: ($spacer * 1.25),
  6: ($spacer * 1.5),
  7: ($spacer * 2),
  8: ($spacer * 2.5),
  9: ($spacer * 3),
  10: ($spacer * 4),
  11: ($spacer * 5),
  12: ($spacer * 6),
  13: ($spacer * 6.5),
);

$sizes: map-merge(
  (
    0:    0%,
    25:   25%,
    50:   50%,
    75:   75%,
    85:   85%,
    100:  100%,
    auto: auto
  ),
  $sizes
);

$numbers: 1, 2, 3, 4, 5, 6;