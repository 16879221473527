body, pre {
  margin: 0;
  color: #000000 !important;
  font-weight: 400;
  font-family: 'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}
.h-616 {
  height: 616;
}

#grayScale {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}